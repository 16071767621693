<template>
  <div>
    <div style="margin:7px 0">
      <a-button type="primary" style="width: 200px" @click="isAdd=true">添加商品</a-button>
    </div>
    <a-table
        ref="table"
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.id"
        :pagination="false"

    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <!--        eslint-disable-next-line vue/no-unused-vars -->
      <template slot="operation" slot-scope="text, record">

        <a @click="product_info(record.id)">修改</a>

      </template>

    </a-table>

    <a-pagination style="float: right;margin-top: 15px;"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />


    <a-modal title="用户修改" :width="600" v-model="editor.show" @ok="product_editor_ok"
             @cancel="product_editor_cancel"
             okText="确定修改" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="商品名称" v-model="editor.tempObj.name"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="商品介绍" v-model="editor.tempObj.introduce" placeholder="商品介绍"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="商品单价" v-model="editor.tempObj.price"/>
      </div>

      <div style="margin-bottom: 16px">
        <a-input addon-before="给予点数" v-model="editor.tempObj.score"/>
      </div>

      <div style="margin-bottom: 16px">
        <a-input addon-before="商品权重" placheholder="0-100" v-model="editor.tempObj.index"/>
      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          商品状态:
        </div>
        <a-select style="width: 85%" v-model="editor.tempObj.state">
          <a-select-option :value="0">隐藏</a-select-option>
          <a-select-option :value="1">显示</a-select-option>
        </a-select>

      </div>

      <div style="margin-bottom: 16px;width: 100%">
        <a-button type="danger" style="width: 100%" @click="del">删除</a-button>
      </div>
    </a-modal>


    <a-modal title="商品添加" :width="600" v-model="isAdd" @ok="add"
             @cancel="()=>{this.Abj={},this.isAdd=false}"
             okText="添加商品" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="商品名称" v-model="Abj.name"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="商品介绍" v-model="Abj.introduce" placeholder="商品介绍"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="商品单价" v-model="Abj.price"/>
      </div>

      <div style="margin-bottom: 16px">
        <a-input addon-before="给予点数" v-model="Abj.score"/>
      </div>

      <div style="margin-bottom: 16px">
        <a-input addon-before="商品权重" placheholder="0-100" v-model="Abj.index"/>
      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          商品状态:
        </div>
        <a-select style="width: 85%" v-model="Abj.state">
          <a-select-option :value="0">隐藏</a-select-option>
          <a-select-option :value="1">显示</a-select-option>
        </a-select>

      </div>

    </a-modal>

  </div>
</template>

<script>
export default {
  name: "Goods",
  data() {
    return {
      curr: 1,
      isAdd: false,
      Abj: {},

      editor: {
        show: false,
        tempObj: {}
      },

      CopyData: {},

      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '商品标题',
          dataIndex: 'name',
          key: 'name',
          align: "center",
        },
        {
          title: '商品介绍',
          dataIndex: 'introduce',
          key: 'introduce',
          align: "center",
          ellipsis: "true",
        },
        {
          title: '商品单价',
          dataIndex: 'price',
          key: 'price',
          align: "center",
        },
        {
          title: '给予点数',
          dataIndex: 'score',
          key: 'score',
          align: "center",
        },
        {
          title: '商品状态',
          dataIndex: 'state',
          key: 'state',
          align: "center",
        },
        {
          title: '商品权重',
          dataIndex: 'index',
          key: 'index',
          align: "center",
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
          key: 'update_time',
          align: "center",
        },
        {
          title: '',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        },
      ],
      data: [],
      returnBack: [],
    }
  },
  methods: {
    product_info(v) {
      this.editor.tempObj = this.data.find(x => x.id == v)
      Object.assign(this.CopyData, this.editor.tempObj)
      this.editor.show = true
    },
    product_editor_ok() {
      this.$axios.post('/Admin/Api/Commodity/editCommodity', {
        ...this.editor.tempObj
      }).then(val => this.$message.success(val.data.msg, 1)).catch(err => {
        this.$message.success(err.msg, 1)
        this.product_editor_cancel()
      })
      this.editor.show = false

    },
    product_editor_cancel() {
      this.$message.warning('取消修改', 1)
      this.editor.show = false
      Object.assign(this.editor.tempObj, this.CopyData)
    },

    del() {
      this.$axios.get(`/Admin/Api/Commodity/delCommodity/${this.editor.tempObj.id}`).then(value => {
        this.$message.success(value.data.msg, 1)
        this.data = this.data.filter(v => v.id != this.editor.tempObj.id)
        this.editor.show = false
      }).catch(err => this.$message.error(err.msg))

    },

    add() {
      this.$axios.post('/Admin/Api/Commodity/addCommodity', {
        ...this.Abj
      }).then(value => {
        this.$message.success(value.data.data)
        setTimeout(() => this.$router.go(0), 500)
      }).catch(err => this.$message.error(err.data)
      )
    }
  },
  watch: {
    curr: {
      async handler(n) {

        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Commodity/getCommodityList', {
          page: n,
          pageCount: 12,
          state: 0
        })).data.data)
        this.data = this.returnBack.list
      }
    },
  },

  async created() {
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Commodity/getCommodityList', {
      page: 1,
      pageCount: 12,
    })).data.data)
    this.data = this.returnBack.list
  }
}
</script>

<style scoped>

</style>